


























import { Component, Prop } from 'vue-property-decorator'

import { LinkWithLabel, PickerCallback, UnresolvedLink } from '../../../../../contracts'
import { ResolvesRelatedAsync } from '../../../../../services'

import { HAlignmentSelector, LinkForm } from '../../../../molecules'

import { AbstractAddon } from '../AbstractAddon.form'

import { LinkAddon } from './Link.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<LinkAddonForm>({
  name: 'LinkAddonForm',
  components: { HAlignmentSelector, LinkForm }
})
export class LinkAddonForm extends AbstractAddon {
  @Prop({ type: Object, required: false })
  public readonly relatedService!: ResolvesRelatedAsync

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  public onLinkUpdate (link: LinkWithLabel | UnresolvedLink): void {
    this._item = { ...this._item, link } as LinkAddon
  }
}
export default LinkAddonForm
